import { Component, HostListener, Renderer2, TemplateRef, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, Router, RouterModule } from '@angular/router';
import { LogBadgeComponent } from '../../log-badge/log-badge.component';
import { SessionService } from '../../../services/shared/session.service';
import { HomePessoaFisicaComponent } from '../../../core/components/pessoa/home-pessoa-fisica/home-pessoa-fisica/home-pessoa-fisica.component';
import { PessoaService } from '../../../services/integration/pessoa.service';
import { AlertComponent } from '../../alert/alert.component';
import { AlertService } from '../../../services/alerts/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDismissReasons, NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaskValidationService } from '../../../services/validation/mask-validation.service';

@Component({
  selector: 'app-navbar',
  imports: [CommonModule, RouterModule, LogBadgeComponent, AlertComponent, NgbTypeahead, ReactiveFormsModule, FormsModule],
  templateUrl: './navbar.component.html',
  standalone: true,
  styleUrl: './navbar.component.css',
})
export class NavbarComponent {
  isNavbarTransparent = false;
  listaAnos: number[] = [];
  form : FormGroup;
  isDarfExiste: boolean = false;

  constructor( private fb: FormBuilder, private renderer: Renderer2,
    protected userLogado: SessionService,
    protected userService: PessoaService,
    protected alertService: AlertService,
    protected router: Router,
    private maskValidation: MaskValidationService) {

      this.router.events.subscribe(() => {
        this.collapseNavbar();
      });

      this.renderer.listen('document', 'click', (event: Event) => {
        const navbarElement = document.querySelector('.navbar-collapse');
        if (navbarElement && navbarElement.classList.contains('show')) {
          const targetElement = event.target as HTMLElement;
          const togglerElement = document.querySelector('.navbar-toggler');

          if (togglerElement && !togglerElement.contains(targetElement) && !navbarElement.contains(targetElement)) {
            this.collapseNavbar();
          }
        }
      });

  }

  initForm() {
    this.form = this.fb.group({
      anoSelecionado: ['', [Validators.required]]
    });
  }

  onSubmit() {
    //se form inválido, retorna
    if (this.form.invalid || this.form.value.anoSelecionado === '') {
      return;
    }
    //por enquanto, apenas dá um console nos valores do form
    console.log(this.form.value);
    //cpf usuário logado
    console.log(this.userLogado.getToken().id)
    }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > 0) {
      this.renderer.addClass(document.querySelector('.navbar'), 'navbar-transparent');
      this.isNavbarTransparent = true;
    } else {
      this.renderer.removeClass(document.querySelector('.navbar'), 'navbar-transparent');
      this.isNavbarTransparent = false;
    }
  }

  deletar() {
    this.userService.delete(this.userLogado.getToken().id).subscribe({
      next: (response) => {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('jwtToken')
        this.userLogado.setToken(null);
        this.alertService.addAlert("Usuário descadastrado", 'success');
        this.router.navigate(['/login']);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.alertService.addAlert(err.error.exception, 'danger!');
      }
    });
  }


  private modalService = inject(NgbModal);
  closeResult = '';

  open(content: TemplateRef<any>) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }


  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }


  @ViewChild('deleteContent') modalContent: TemplateRef<any>;

  collapseNavbar() {
    const navbarElement = document.querySelector('.navbar-collapse');
    if (navbarElement && navbarElement.classList.contains('show')) {
      this.renderer.removeClass(navbarElement, 'show');
    }
  }

  gerarAnos(): void {
    const anoAtual = new Date().getFullYear();
    const anoInicial = 2024;

    this.listaAnos = [];
    // pega o ano inicial e calcula até o ano anterior ao ano corrente
    for (let ano = anoInicial; ano < anoAtual; ano++) {
        if (!this.listaAnos.includes(ano)) { // Verifica se o ano já está na lista
            this.listaAnos.push(ano);
        }
    }
}

verificarDarf(id: string, ano: string, control: any): void {
  this.maskValidation.validateDirfApi(id, ano).subscribe(
    (hasDarf: boolean) => {
      if (!hasDarf) {
        control.setErrors({ darfNotFound: true });
        this.isDarfExiste = false; // Limpa o estado de sucesso
      } else {
        control.setErrors(null);
        this.isDarfExiste = true; // Define o estado de sucesso
      }
    },
    (error: any) => {
      console.error('Erro ao validar DARF:', error);
      control.setErrors({ apiError: true });
      this.isDarfExiste = false; // Limpa o estado de sucesso em caso de erro
    }
  );
}


  onAnoChange(): void {
  const ano = this.form.get('anoSelecionado')?.value;
  const id = this.userLogado.getToken().id;
  const control = this.form.get('anoSelecionado');

  if (ano && control) {
    this.verificarDarf(id, ano, control);
  }
  }


  downloadDirf() {
    const id = this.userLogado.getToken().id;
    const nomeUsuario = this.userLogado.getToken().name;
    const anoConsulta = this.form.value.anoSelecionado;
    this.userService.downloadDirf(id, anoConsulta).subscribe((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${nomeUsuario} - DIRF_${anoConsulta}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }
}
