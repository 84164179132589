<div class="button-container" style="margin-bottom: -10px;" style=" justify-content:flex-end">
  <app-log-badge style="margin-right: 30px; cursor: pointer;"></app-log-badge>
</div>
<nav class="navbar navbar-expand-lg" style="padding: 20px;">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      <div class="container">
        <img class="img-left" src="/assets/banners-home/logo_nota_premiada_01.png" alt="NOTA PREMIADA">
        <div class="vertical-line"></div>
        <img class="img-right" src="/assets/banners-home/brasao_governo_horizontal.png" />
      </div>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse ml-auto" id="navbarSupportedContent" style="justify-content: flex-end;">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <b style="text-transform: capitalize;">O programa</b>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="color: darkblue;">
            <li><a class="dropdown-item" style="text-transform: none;" routerLink="/programa/conheca-programa">Conheça o
                Programa</a></li>
            <li><a class="dropdown-item" routerLink="/programa/educacao-fiscal"
                style="text-transform: capitalize;">Educação Fiscal</a></li>
            <li><a class="dropdown-item" routerLink="/programa/legislacao"
                style="text-transform: capitalize;">Legislação </a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false" *ngIf="!(userLogado.getToken()?.profile == 'institution') || !userLogado?.getToken()">
            <b style="text-transform: capitalize;">Pessoa Física</b>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" routerLink="/pessoa/cadastro" style="text-transform: capitalize;"
                *ngIf="!(userLogado.getToken())">Cadastro de
                Pessoa Fisica</a></li>
            <li><a class="dropdown-item" routerLink="/pessoa/atualizar" style="text-transform: capitalize;"
                *ngIf="(userLogado?.getToken())">Alterar
                Dados</a></li>
            <li><a class="dropdown-item" routerLink="/home-usuario" style="text-transform: capitalize;"
                *ngIf="(userLogado?.getToken())">Home Usuário</a></li>
            <li><a class="dropdown-item" routerLink="/pessoa/minhas-notas" style="text-transform: capitalize;"
                *ngIf="(userLogado?.getToken())">Minhas Notas</a></li>
            <li><a class="dropdown-item" routerLink="/pessoa/meus-pontos" style="text-transform: capitalize;"
                *ngIf="(userLogado?.getToken())">Meus Pontos</a></li>
            <li><a class="dropdown-item" routerLink="/pessoa/premios" style="text-transform: capitalize;"
                *ngIf="(userLogado?.getToken())">Meus Prêmios</a></li>
            <li><a class="dropdown-item" routerLink="/pessoa/minhas-mensagens" style="text-transform: capitalize;"
                *ngIf="(userLogado?.getToken())">Minhas Mensagens</a></li>
            <li><a class="dropdown-item" style="text-transform: capitalize; cursor: pointer;"
              *ngIf="(userLogado?.getToken())" (click)="open(declaracaoIRRF); gerarAnos(); initForm()">Declaração Rendimento - IRRF</a></li>
            <li><a class="dropdown-item" style="text-transform: capitalize; cursor: pointer;"
                (click)="open(deleteContent)" *ngIf="(userLogado?.getToken())">Descadastrar</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false"
            *ngIf="(!userLogado?.getToken() || userLogado.getToken()?.profile == 'institution') || userLogado.getToken()?.profile == 'Super Admin'">
            <b style="text-transform: capitalize;">Entidades Sociais</b>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" routerLink="/entidade-social/cadastro"
                style="text-transform: capitalize;">Solicitar cadastramento de Entidade </a>
            </li>
            <li><a class="dropdown-item" routerLink="/entidade-social/entidades-cadastradas"
                style="text-transform: capitalize;">Entidades Cadastradas</a>
            </li>
            <li><a class="dropdown-item" routerLink="/entidade-social/documentacao"
                style="text-transform: capitalize;">Documentação Necessária</a></li>
            <li><a class="dropdown-item" routerLink="/entidade-social/descadastro"
                *ngIf="userLogado.getToken()?.profile == 'institution'" style="text-transform: capitalize;">Solicitar
                Descadastramento</a></li>
            <li><a class="dropdown-item" routerLink="/entidade-social/solicitar-alteracao"
                *ngIf="userLogado.getToken()?.profile == 'institution'" style="text-transform: capitalize;">Solicitar
                Alteração</a></li>
            <li><a class="dropdown-item" routerLink="/entidade-social/meus-premios"
                *ngIf="userLogado.getToken()?.profile == 'institution'" style="text-transform: capitalize;">Meus
                Prêmios</a></li>
            <li><a class="dropdown-item" routerLink="/entidade-social/acompanhar-solicitacao"
                style="text-transform: capitalize;">Acompanhar Solicitação</a>
            </li>
          </ul>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <b style="text-transform: capitalize;">Sorteios</b>
          </a>
          <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" routerLink="/sorteio/como-funciona" style="text-transform: capitalize;">Como
                Funciona</a></li>
            <li><a class="dropdown-item" routerLink="/sorteio/como-receber" style="text-transform: capitalize;">Como
                Receber</a></li>
            <li><a class="dropdown-item" routerLink="/sorteio/instituicoes-ganhadoras"
                style="text-transform: capitalize;">Prêmio Rateio</a></li>
            <li><a class="dropdown-item" routerLink="/sorteio/sorteios-realizados"
                style="text-transform: capitalize;">Sorteio Realizados</a></li>
          </ul>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <b style="text-transform: capitalize;">Ajuda</b>
          </a>
          <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" routerLink="/ajuda/gestao-programa" style="text-transform: capitalize;">Gestão
                do Programa</a></li>
                <li><a class="dropdown-item" href="../../assets/manual_usuario/manual_usuario_externo.pdf"
                  download="Manual Usuário (Pessoa Fisica e Entidade Social) - Nota Premiada Acreana.pdf"
                  style="text-transform: capitalize;">Manual do usuário</a></li>
            <li><a class="dropdown-item" routerLink="/ajuda/material-divulgacao"
                style="text-transform: capitalize;">Material de
                Divulgação</a></li>
            <li><a class="dropdown-item" routerLink="/ajuda/fale-conosco" style="text-transform: capitalize;">Fale
                Conosco</a></li>
            <li><a class="dropdown-item" routerLink="/ajuda/reativar-cadastro" *ngIf="!userLogado.getToken()"
                style="text-transform: capitalize;">Reativar Cadastro</a></li>
            <li><a class="dropdown-item" routerLink="/ajuda/reenviar-email" *ngIf="!userLogado.getToken()"
                style="text-transform: capitalize;">Reenviar Email</a></li>
          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown5" role="button" data-bs-toggle="dropdown"
            aria-expanded="false" *ngIf="(userLogado?.getToken()?.tag == 'backoffice')">
            <b>BACKOFFICE</b>
          </a>
          <ul class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="navbarDropdown5">
            <li><a class="dropdown-item" routerLink="/listar-solicitacoes">LISTA DE SOLICITAÇÃO</a></li>
            <li><a class="dropdown-item"
                href="https://notapremiadaacreanacmsdevsefazac.lampp-it.com.br/dashboard/auth/login"
                target="_blank">MANUTENÇÃO DE NOTÍCIAS</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>


<ng-template #deleteContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Descadastramento</h4>
    <button type="button" class="btn-close" aria-label="Close" ng-template
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        Você deseja se DESCADASTRAR do NOTA PREMIADA ACREANA?
      </div>
    </form>
  </div>
  <div class="modal-footer" style="display: flex;     justify-content: space-between; ">
    <button class="btn btn-outline-secondary" (click)="modal.close('Save click')">Não </button>
    <button type="button" class="btn btn-outline-success" (click)="deletar(); modal.close('Fechou')">Sim</button>
  </div>
</ng-template>

<ng-template #declaracaoIRRF let-modal>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Declaração de Rendimento - IRRF</h4>
      <button type="button" class="btn-close" aria-label="Close" ng-template
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-3 px-2">
          <p>
            Para emitir o Comprovante de Rendimentos Pagos e de Imposto sobre a Renda Retido na Fonte, selecione um ano:
          </p>
          <div class="col-sm-6">
            <label for="ano" class="form-label">Ano-calendário</label>
            <select id="ano" formControlName="anoSelecionado" class="form-select" (change)="onAnoChange()" required>
              <option value="" selected> -- Selecione -- </option>
              <option *ngFor="let ano of listaAnos" [value]="ano">{{ ano }}</option>
            </select>
          </div>

          <!-- Exibe mensagem de erro apenas se o campo foi tocado ou alterado -->
          <div *ngIf="form.get('anoSelecionado')?.touched && form.get('anoSelecionado')?.hasError('darfNotFound')">
            <p style="color: red;">Declaração de Rendimentos não encontrada! Não existe declaração para o ano selecionado.</p>
          </div>

          <div *ngIf="form.get('anoSelecionado')?.touched && form.get('anoSelecionado')?.hasError('apiError')">
            <p style="color: red;">Erro ao buscar Declaração de Rendimentos para o ano selecionado. Tente novamente.</p>
          </div>

          <!-- Exibe mensagem de sucesso apenas se o campo foi alterado -->
          <div *ngIf="form.get('anoSelecionado')?.dirty && isDarfExiste">
            <p style="color: green;">Declaração de Rendimentos encontrada! Selecione emitir para realizar o download.</p>
          </div>
    </div>
    <div class="modal-footer" style="display: flex;     justify-content: space-between; ">
      <button class="btn btn-outline-secondary m-0" (click)="modal.close('Save click')">Voltar </button>
      <button type="submit" [disabled]="form.invalid || form.value.anoSelecionado === ''" class="btn btn-outline-success m-0" (click)="modal.close('Fechou'); downloadDirf()">Emitir</button>
    </div>
    </div>
    </form>
</ng-template>
